import React from 'react';
import './Services.css'; 

function Services() {
  return (
    <section className="services">
      <div className="container">
        <div className="services-content"> 
          <h2 className="section-header">
            Empowering Your Business with Tailored Cloud Solutions
          </h2>
        </div>

        <div className="services-list">
          <div className="service">
            <img src="/images/ai-icon.svg" alt="AI Icon" className="service-icon" /> 
            <h3 className="service-title">AI & Machine Learning</h3>
            <p className="service-description">
              Unlock the potential of your data with custom AI and ML solutions. We'll help you leverage powerful insights to optimize operations, engage customers, and gain a competitive edge.
            </p>
          </div>

          <div className="service">
            <img src="/images/app-modernization-icon.svg" alt="App Modernization Icon" className="service-icon" /> 
            <h3 className="service-title">App Modernization</h3>
            <p className="service-description">
              Transform your legacy applications for the cloud-native world. We'll help you improve scalability, reliability, and security while reducing costs and increasing agility. 
            </p>
          </div>

          <div className="service">
            <img src="/images/infrastructure-icon.svg" alt="Infrastructure Icon" className="service-icon" /> 
            <h3 className="service-title">Infrastructure Modernization</h3>
            <p className="service-description">
              Build a future-proof IT infrastructure on GCP. We'll help you design, migrate, and manage secure, scalable, and cost-effective cloud solutions tailored to your specific needs.
            </p>
          </div>
        </div> 
      </div>
    </section>
  );
}

export default Services;