import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services'; 
import Footer from './components/Footer'; // Import Footer

function App() {
  return (
    <div className="App">
      <Header /> 
      <Hero /> 
      <Services />
      <Footer />  {/* Include the Footer component */}
    </div>
  );
}

export default App; 