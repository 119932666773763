import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <img src="/images/logo.png" alt="Cloud-Nut Logo" />
        </div>
        <div className="header-text"> 
          Empowering businesses to transform, modernize, and thrive in the digital future — with a trusted partner. 
        </div>
      </div>
    </header>
  );
}

export default Header;