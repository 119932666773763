// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: #1a202c; 
  color: #ffffff;             
  padding: 1rem 0;              
}

.container { 
  max-width: 960px;             
  margin: 0 auto;                
  padding: 0 1rem;              
}

.logo { 
  font-size: 1.5rem; 
  font-weight: bold;
  display: flex;            
  justify-content: center;  
  max-width: 150px; 
  height: auto;  
  margin: 1rem 0;  /* Add top/bottom margin */ 
}

.logo img {
  width: 100%;
}

.header-text {
  font-size: 1.125rem;      /* Adjust font size as needed */
  font-weight: 600;
  color: #ffffff;           /* Set text color (white in this example) */
  margin-left: 2rem;        /* Add spacing between logo and text */
  line-height: 1.4;         /* Adjust line height for readability */
  text-align: left;         /* Align the tagline to the left */ 
}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,cAAc,GAAG,0BAA0B;AAC7C;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB,OAAO,+BAA+B;EACzD,gBAAgB;EAChB,cAAc,YAAY,2CAA2C;EACrE,iBAAiB,SAAS,sCAAsC;EAChE,gBAAgB,UAAU,uCAAuC;EACjE,gBAAgB,UAAU,kCAAkC;AAC9D","sourcesContent":[".header {\n  background-color: #1a202c; \n  color: #ffffff;             \n  padding: 1rem 0;              \n}\n\n.container { \n  max-width: 960px;             \n  margin: 0 auto;                \n  padding: 0 1rem;              \n}\n\n.logo { \n  font-size: 1.5rem; \n  font-weight: bold;\n  display: flex;            \n  justify-content: center;  \n  max-width: 150px; \n  height: auto;  \n  margin: 1rem 0;  /* Add top/bottom margin */ \n}\n\n.logo img {\n  width: 100%;\n}\n\n.header-text {\n  font-size: 1.125rem;      /* Adjust font size as needed */\n  font-weight: 600;\n  color: #ffffff;           /* Set text color (white in this example) */\n  margin-left: 2rem;        /* Add spacing between logo and text */\n  line-height: 1.4;         /* Adjust line height for readability */\n  text-align: left;         /* Align the tagline to the left */ \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
