import React from 'react';
import './Footer.css'; 

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content"> 
          <div className="footer-section">
            <h3>Cloud-Nut</h3>
            <p>Partner with Cloud-Nut to transform your business</p>
          </div>
          <div className="footer-section">
            <h3>Contact Us</h3>
            <p>Email: info@cloud-nut.com</p> 
          </div>
          <div className="footer-section">
            <h3>Follow Us</h3>
            <ul className="social-links"> 
              <li>
                <a 
                  href="https://www.linkedin.com/company/cloud-nut" 
                  target="_blank" 
                  rel="noopener noreferrer"
                > 
                  <img src="/images/linkedin-icon.svg" alt="LinkedIn" /> 
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom"> 
          <img 
            src="/images/gcp-partner-badge.png" 
            alt="Google Cloud Partner Badge"
            className="gcp-partner-badge"
          />
          <p className="powered-by">Powered by Gemini</p>
          <p>© {new Date().getFullYear()} Cloud-Nut. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;