import React from 'react';
import './Hero.css'; 

function Hero() {
  return (
    <section className="hero"> 
      <div className="container"> 
        <div className="hero-image"> 
          <img src="/images/gcp-logo.svg" alt="Google Cloud Logo" />
        </div>

        <div className="hero-content"> 
          <h1 className="hero-header">Build Smarter Businesses with AI & the Cloud</h1> 
          <p className="hero-text">Unlock the power of AI, modernization, and seamless cloud migration with our expert solutions. Partner with Cloud-Nut to transform your business on Google Cloud Platform.</p>

          {/* LaunchList Form */} 
          <form 
            className="launchlist-form" 
            action="https://getlaunchlist.com/s/ZzAyN8" 
            method="POST"
          >
            <input name="email" type="email" placeholder="Your Email" required />
            <button type="submit" className="cta-button">Join Our Waitlist</button>
          </form> 
        </div>
      </div>
    </section>
  );
}

export default Hero;